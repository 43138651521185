// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/Categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-contact-me-js": () => import("./../../../src/pages/ContactMe.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-cv-bikes-js": () => import("./../../../src/pages/CVBikes.js" /* webpackChunkName: "component---src-pages-cv-bikes-js" */),
  "component---src-pages-death-investigation-js": () => import("./../../../src/pages/DeathInvestigation.js" /* webpackChunkName: "component---src-pages-death-investigation-js" */),
  "component---src-pages-fitopoly-js": () => import("./../../../src/pages/Fitopoly.js" /* webpackChunkName: "component---src-pages-fitopoly-js" */),
  "component---src-pages-fivs-123-js": () => import("./../../../src/pages/FIVS123.js" /* webpackChunkName: "component---src-pages-fivs-123-js" */),
  "component---src-pages-focs-js": () => import("./../../../src/pages/FOCS.js" /* webpackChunkName: "component---src-pages-focs-js" */),
  "component---src-pages-game-dev-js": () => import("./../../../src/pages/GameDev.js" /* webpackChunkName: "component---src-pages-game-dev-js" */),
  "component---src-pages-gradient-js": () => import("./../../../src/pages/Gradient.js" /* webpackChunkName: "component---src-pages-gradient-js" */),
  "component---src-pages-how-i-work-js": () => import("./../../../src/pages/HowIWork.js" /* webpackChunkName: "component---src-pages-how-i-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mws-js": () => import("./../../../src/pages/MWS.js" /* webpackChunkName: "component---src-pages-mws-js" */),
  "component---src-pages-pomodoro-js": () => import("./../../../src/pages/Pomodoro.js" /* webpackChunkName: "component---src-pages-pomodoro-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/Results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-simone-js": () => import("./../../../src/pages/Simone.js" /* webpackChunkName: "component---src-pages-simone-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/Tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/Thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-tribute-js": () => import("./../../../src/pages/Tribute.js" /* webpackChunkName: "component---src-pages-tribute-js" */),
  "component---src-pages-u-take-it-js": () => import("./../../../src/pages/UTakeIt.js" /* webpackChunkName: "component---src-pages-u-take-it-js" */),
  "component---src-pages-uxlx-js": () => import("./../../../src/pages/UXLX.js" /* webpackChunkName: "component---src-pages-uxlx-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

