import React from 'react';


class Footer extends React.Component {
  render() {
    return (
      <section className='mt6'>
        <footer className="pv4 ph3 ph5-ns tc">
          <small className="f6 db hint-text pv3 tc">© 2019 K. ANTHONY, ALL RIGHTS RESERVED</small>
        </footer>
      </section>

    )
  }
}
export default Footer;
